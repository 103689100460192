@value tablet-small from '../../../../../../shared/ui/src/lib/styles/values/breakpoints.css';

.container {
  border-color: #e4e7eb;
  margin-top: calc(4 * var(--dex-spacing-1x));
}

.container :global .yn-prompt-large-heading {
  font-family: var(--core-type-display-10-font-family);
}

@media only screen and bp-tablet-small-viewport {
  .container {
    margin-top: calc(9 * var(--dex-spacing-1x));
  }
}

@media only screen and (max-width: tablet-small) {
  .container :global .yn-prompt-container div.yn-prompt-large-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .container
    :global
    div.yn-prompt-large-container
    .yn-prompt-large-button-group {
    margin-left: 0;
  }
}
