.show-markdown-pre {
  text-wrap: wrap;
}

.heading {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: var(--dex-spacing-1x);

  position: relative;
  z-index: 1;
}

.spinner {
  width: calc(5 * var(--dex-spacing-1x));
  height: calc(5 * var(--dex-spacing-1x));
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Set minimum height for empty state */
  min-height: 178px;

  animation: fadeIn ease 2.5s;
}
