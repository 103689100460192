.divider {
  height: 1px;
  width: 40px;
  background-color: var(--dex-border-color);
  margin: 16px 8px;
}
.depth-space {
  display: inline-flex;
  width: 14px;
}
.link {
  display: block;
  text-decoration: none;
  margin: 0;
  flex-grow: 1;
  transition: color 0.15s ease;
  cursor: pointer;
}
.link.top-level {
  font-weight: 600;
  padding: 8px;
}
.link.sub-level {
  padding: 6px 8px;
  font-weight: 400;
  line-height: 18px;
  color: inherit;
}
.link.active {
  color: var(--core-blue-text-color);
}
.link.as-div {
  flex-grow: 0;
}
