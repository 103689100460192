@value tablet-medium, tablet-large from '../../../../../../../shared/ui/src/lib/styles/values/breakpoints.css';

.requirements-list {
  --sticky-container-top: var(--requirements-list-top, 0);
  --requirements-list-margin-bottom: calc(6 * var(--dex-spacing-1x));
  --header-height: 46px;
}

.scrollable-el {
  overflow: auto;
  max-height: calc(
    100vh - var(--sticky-container-top) - var(--header-height) -
      var(--requirements-list-margin-bottom)
  );
  margin-bottom: var(--requirements-list-margin-bottom);

  padding-left: calc(3 * var(--dex-spacing-1x));
  padding-right: calc(3 * var(--dex-spacing-1x));
}

.requirements-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--header-height);
}

.requirement-row-label {
  --row-small-size-text-weight: 400;
}

.no-requirements-text {
  text-align: center;
}

market-row:hover .side-label {
  visibility: visible;
}

.row {
  /* By default, market rows have 16px horizontal spacing. But because
  the Details link has 12px of padding, we have to adjust */
  --row-horizontal-spacing: calc(0.5 * var(--dex-spacing-1x));
}

.row::part(side) {
  /* Need this to ensure the Details link fills the row height */
  align-self: stretch;
}

.side-label,
.hidden-side-label {
  color: var(--core-emphasis-text-color);
  font-weight: 600;
  visibility: hidden;
  text-decoration: none;
  /* Grow the target area such that it clips the row's padding as well */
  margin: calc(-1 * var(--row-small-size-vertical-padding)) 0;
  padding: calc(var(--row-small-size-vertical-padding))
    calc(1.5 * var(--dex-spacing-1x));
  cursor: pointer;

  /* Fill the row height and center */
  display: flex;
  align-items: center;
  flex: 1;
}

.spinner {
  width: calc(5 * var(--dex-spacing-1x));
  height: calc(5 * var(--dex-spacing-1x));
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Match height of empty state */
  min-height: 178px;

  animation: fadeIn ease 2.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: tablet-medium) and (max-width: tablet-large) {
  .side-label {
    padding-left: calc(0.25 * var(--dex-spacing-1x));
    padding-right: calc(0.25 * var(--dex-spacing-1x));
  }

  .scrollable-el {
    padding-left: calc(1.5 * var(--dex-spacing-1x));
    padding-right: calc(1.5 * var(--dex-spacing-1x));
  }
}
