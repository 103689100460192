.divider {
  height: 16px;
  width: 1px;
  background-color: var(--dex-border-color);
}
.space {
  flex-grow: 1;
}
.space + .divider,
.divider + .divider {
  display: none;
}
.link {
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.15s ease;
}
.link:first-child {
  margin-left: 8px;
}
.link:last-child {
  margin-right: 8px;
}
.link:hover, .active {
  color: var(--core-blue-text-color);
}
