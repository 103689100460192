.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.breadcrumb {
  display: flex;
  text-decoration: none;
}

a.breadcrumb:hover {
  color: var(--core-emphasis-text-color);
}

a.breadcrumb:hover path {
  fill: var(--core-emphasis-text-color);
  fill-opacity: 1;
}
