@value bp-tablet-medium-viewport from '../../../../../shared/ui/src/lib/styles/values/breakpoints.css';

.top-nav {
  display: flex;
  background: white;
  align-items: center;
}
.top-nav.desktop {
  display: none;
  height: 48px;
}
.top-nav.mobile {
  height: 60px;
  font-weight: 500;
  color: #373f4a;
  justify-content: space-between;
}
.docs-label-mobile {
  font-size: 16px;
  margin-left: 24px;
}
.mobile-open-button {
  margin-right: 24px;
}

@media only screen and bp-tablet-medium-viewport {
  .top-nav.desktop {
    display: flex;
  }
  .top-nav.mobile {
    display: none;
  }
}
