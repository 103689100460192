.nav-item-wrap {
  display: flex;
  align-items: stretch;
  border-radius: 8px;
  color: var(--core-text-20-color);
}
.top-level a {
  margin: 4px 0;
}
.nav-item-wrap a {
  display: flex;
}
.nav-item-wrap:hover {
  background-color: var(--dex-nav-button-color);
  color: var(--core-text-10-color);
}
.nav-item-wrap:hover .expand-button path {
  fill: var(--core-text-20-color);
}
.nav-item-wrap.inner-sub-cat {
  color: var(--core-text-10-color);
}
.nav-item-wrap.inner-sub-cat a {
  flex-grow: 0;
}
.expand-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding-left: 2px;
  padding-right: 8px;
}
.expand-button svg {
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out;
}
.expand-button.expanded svg {
  transform: rotate(180deg);
}
.expand-button path {
  fill: var(--core-fill-30-color);
}

.items-container {
  display: grid;
  grid-template-rows: 0fr;
  transition: all 0.25s ease-in-out;
}

.items-container.expanded {
  grid-template-rows: 1fr;
}

.content {
  overflow: hidden;
}
