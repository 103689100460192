@value bp-tablet-medium-viewport from '../../../../../shared/ui/src/lib/styles/values/breakpoints.css';

.left-nav {
  background-color: white;
  position: relative;
  overflow-y: scroll;
  width: 100%;
  display: none;
}
.left-nav-toggle {
  position: fixed;
  transform: translate(52px, 24px);
  background-color: var(--core-fill-40-color);
  transition: transform var(--dex-docs-layout-transition-timing);
  --toggle-width: 28px;
  width: var(--toggle-width);
  height: var(--toggle-width);
  display: flex;
  justify-content: center;
  align-items: center;
}
.left-nav-toggle path {
  fill: black;
}
.left-nav-toggle.collapsed {
  transform: translate(calc(var(--toggle-width) + 32px), 24px) rotate3d(0,1,0,180deg);
}
@media screen and bp-tablet-medium-viewport {
  .left-nav {
    display: block;
  }
}

