@value tablet-medium from '../../../../../shared/ui/src/lib/styles/values/breakpoints.css';

.mobile-nav {
  background-color: var(--dex-ui-app-color);
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: 1;
  overscroll-behavior: contain;
  visibility: hidden;
  opacity: 0;
  /* TODO (spencers): fade-in transition not working due to re-render on open/close in DocsBasePage */
  /* transition: all 0.3s; */
  overflow-y: scroll;
}
.mobile-nav.open {
  visibility: visible;
  opacity: 1;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top.sub-page {
  align-items: flex-start;
}
.close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--core-fill-40-color);
  height: 40px;
  width: 40px;
}
.close-button path {
  fill: var(--core-fill-10-color);
}
.nav-item {
  display: flex;
  justify-content: space-between;
}
.nav-item:last-of-type {
  border: none;
}
.nav-item .link {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--core-text-10-light-mode-color);
  text-decoration: none;
}
.nav-item .link.active {
  color: var(--core-emphasis-fill-color);
}
.nav-item button {
  background-color: var(--core-fill-40-color);
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  justify-content: center;
}
.nav-item path {
  fill: var(--core-fill-10-color);
}
button.mobile-nav-back {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--core-fill-40-color);
  height: 40px;
  width: 40px;
}
button.mobile-nav-back svg path {
  fill: var(--core-fill-10-color);
}
@media screen and (min-width: tablet-medium) {
  .mobile-nav {
    display: none;
  }
}
