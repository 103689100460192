.docs-floating-toc {
  --floating-toc-margin-bottom: calc(12 * var(--dex-spacing-1x));

  --sticky-container-top: var(--floating-toc-top, 0);
}

.docs-scrollable-el {
  overflow: auto;
  max-height: calc(
    100vh - var(--sticky-container-top) - var(--floating-toc-margin-bottom)
  );

  margin-bottom: var(--floating-toc-margin-bottom);
}

.docs-item {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.docs-floating-toc .docs-item.selected,
.app-submission-floating-toc .app-submission-item.selected {
  color: var(--core-emphasis-text-color);
}

/* app-submission */
.app-submission-floating-toc {
  --sticky-container-top: var(--floating-toc-top, 0);
  --floating-toc-margin-bottom: calc(2 * var(--dex-spacing-1x));
}

.modal-scrollable-el {
  overflow: auto;
  /* market-modal-full padding + market header + market header margin + 
  integration check heading + ftoc margin + ftoc heading + ftoc-scroll margin-top */
  --header-top: calc(16px + 72px + 24px + 88px + 8px + 24px + 12px);
  max-height: calc(
    100vh - var(--sticky-container-top) - var(--floating-toc-margin-bottom) -
      var(--header-top)
  );
}

.completed-icon path {
  fill: var(--core-success-fill-color);
}
.incomplete-icon path {
  transform: translateX(3px);
}
