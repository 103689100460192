@value tablet-small from '../styles/values/breakpoints.css';

.inline {
  display: block;
  height: calc(5 * var(--dex-spacing-1x));
}

.large {
  display: block;
  min-height: calc(7 * var(--dex-spacing-1x));
}

@media only screen and (max-width: tablet-small) {
  .large {
    /* This is the height of the feedback at narrow widths */
    min-height: 98px;
  }
}
