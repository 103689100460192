.list {
  --row-small-size-text-weight: var(--core-type-paragraph-30-weight);
  --row-small-size-text-size: var(--core-type-paragraph-30-size);
  --row-small-size-text-leading: var(--core-type-paragraph-30-leading);
  --row-small-size-min-height: calc(3 * var(--dex-spacing-1x));
  --row-small-size-vertical-padding: 0;
}

/* We need to set some styling on market components before hydration, otherwise they will have a height
of 0 during SSR, and will suddenly shift the content after SSR.
We can't use classNames to pass non-hydrated properties to market components, because market
components don't add the className til after hydration. So if we want SSR styling before hydration,
we need to target the element itself */
.list market-list {
  display: block;
  --row-small-size-min-height: calc(3 * var(--dex-spacing-1x));
  --list-row-margin-top: var(--dex-spacing-1x);
  --row-container-gap: var(--dex-spacing-1x);
  --row-control-width: 20px;

  min-height: calc(
    var(--list-num-rows, 0) *
      calc(var(--row-small-size-min-height) + var(--list-row-margin-top))
  );
}

.list market-row {
  display: block;
  min-height: var(--row-small-size-min-height);
  margin-top: var(--list-row-margin-top);
}

.list market-row:not([hydrated]) {
  margin-left: calc(var(--row-control-width) + var(--row-container-gap));
}

.option-row::part(container) {
  background: none;
  column-gap: var(--row-container-gap);
  align-items: flex-start;
}

.option-row [slot='control'] {
  margin-top: 2px;
}

.option-row::before {
  --row-normal-variant-separator-height: 0;
}

.option-row:hover,
.option-row:focus {
  background: none;
}

@keyframes append-animate {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.new-item {
	animation: append-animate .5s ease-in;
}

@keyframes remove-animate {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.remove-item {
	animation: remove-animate .5s ease-out;
}

.short-answer-textarea {
  margin-bottom: var(--dex-spacing-1x);
}