.block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.url-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.anchor {
  color: var(--core-emphasis-text-light-mode-color);
  text-decoration: none;
}

.arrow {
  color: var(--core-emphasis-text-light-mode-color);
}

.end {
  text-align: end;
}
