@value bp-tablet-medium-viewport, bp-tablet-large-viewport, bp-desktop-medium-viewport, desktop-medium from '../../../../../shared/ui/src/lib/styles/values/breakpoints.css';

/* Page Layout */
.page-layout {
  width: 100%;
  --top-bar-height: 61px;
  --docs-left-nav-width: 320px;

  /* Need to adjust the anchor headings based on the top bar height */
  --anchor-heading-top: calc(-1 * var(--top-bar-height));
  --table-header-sticky-position: var(--top-bar-height);

  transition: grid-template-columns var(--dex-docs-layout-transition-timing);
}

.content-main-grid-item {
  --dex-table-max-width: calc(
    100vw - var(--dex-grid-margin-left) - var(--dex-grid-margin-right)
  );
}

.grid-item-column-autosizing {
  --dex-grid-item-full-size-column-span: 1;
}

.content-main-grid-item:has([data-html-highlighter]) {
  position: relative;
  border: 2px solid var(--core-red-fill-color);
  padding: 16px;
  border-radius: 6px;
}

.content-main-grid-item:has([data-html-highlighter]):before {
  content: 'Invalid markup found';
  display: block;
  position: absolute;
  top: -24px;
  left: 0;
  font-weight: 500;
}

@media screen and bp-tablet-medium-viewport {
  .page-layout {
    --top-bar-height: 108px;
  }
}

.fixed-layout {
  position: fixed;
  z-index: 2;
}

.left-nav-collapsed {
  grid-template-columns: 0 auto;
}

.left-layout {
  display: none;
  top: var(--top-bar-height);
  height: calc(100vh - var(--top-bar-height));
  left: 0;
  width: var(--docs-left-nav-width);
  justify-content: flex-end;
  margin-left: 0;
  transition: margin-left var(--dex-docs-layout-transition-timing);
}
.left-layout.collapsed {
  margin-left: calc(-1 * var(--docs-left-nav-width));
}

.static-layout {
  margin-top: var(--top-bar-height);
}

.sticky {
  position: sticky;
  top: 0;
}

/* Be above other layouts */
.top-layout {
  z-index: 1;
  /* Awkward padding to fix alignment issue in the svelte header */
  padding: 0 14px;
  border-bottom: 1px solid var(--dex-border-color);
}

@media screen and bp-tablet-medium-viewport {
  .top-layout {
    border-bottom: none;
  }
}

/* Main */
.page-main {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.content-center {
  display: flex;
  justify-content: center;
}

.content-full-width {
  margin-left: calc(8 * var(--dex-spacing-1x));
  margin-right: calc(8 * var(--dex-spacing-1x));
  max-width: 100%;
}

/* Content */

.footer :global .footer-wrapper footer.dex-site-footer {
  padding-left: 0;
  padding-right: 0;
}

.sidebar-floating-toc {
  display: none;
  --floating-toc-margin: calc(6 * var(--dex-spacing-1x));
  /* Adjust the floating TOC height for the top bar and heading height */
  --floating-toc-top: calc(var(--top-bar-height) + var(--floating-toc-margin));
}

.sidebar-requirements {
  margin-top: calc(3 * var(--dex-spacing-1x));
  --requirements-list-margin: calc(6 * var(--dex-spacing-1x));
  --requirements-list-top: calc(
    var(--top-bar-height) + var(--requirements-list-margin)
  );
}

.grid-requirements {
  /* This allows for the requirements pages to keep the right sidebar in view longer */
  --dex-docs-content-grid-item-medium-size-column-span-medium: 1;
  grid-template-columns: 1fr;
}

/* Fix the fact that the footer wasn't spanning the page */
.footer {
  grid-column: auto / span 3;
}

/* Responsive */

@media only screen and bp-tablet-medium-viewport {
  .grid-requirements {
    /* This allows for a 50/50 split, keeps the gap, and both columns shrink at the same time */
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: calc(1 * var(--dex-spacing-1x));
  }

  .sidebar-requirements {
    margin-top: 0;
  }

  .content-main-grid-item {
    --dex-table-max-width: 928px;
  }

  .page-main {
    max-width: 928px;
  }

  .left-nav {
    display: block;
  }

  .left-layout {
    display: flex;
  }

  .limit-width {
    max-width: 700px;
  }

  .editor-page {
    width: calc(100% - 32px * 2);
    max-width: none;
    margin-left: calc(4 * var(--dex-spacing-1x));
    margin-right: calc(4 * var(--dex-spacing-1x));
  }
}

@media only screen and bp-desktop-medium-viewport {
  .sidebar-floating-toc {
    display: block;
  }

  .grid-item-column-autosizing {
    --dex-grid-item-full-size-column-span: 2;
  }

  .grid-requirements {
    /* Force the column-gap to always be 56px */
    column-gap: calc(7 * var(--dex-spacing-1x));
  }
}

/* Override the svelte footer breakpoint */
@media only screen and (max-width: desktop-medium) {
  .footer :global .footer-wrapper footer.dex-site-footer {
    grid-template-columns: repeat(3, 1fr);
  }
  .footer :global .footer-wrapper footer.dex-site-footer button {
    text-wrap: wrap;
    text-align: inherit;
  }
  .footer
    :global
    .footer-wrapper
    footer.dex-site-footer
    .dex-site-footer__square-container {
    text-align: left;
  }
}

@media only screen and (max-width: 700px) {
  .footer :global .footer-wrapper footer.dex-site-footer {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
  }
}
