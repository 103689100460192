@value bp-tablet-large-viewport from '../../../../ui/src/lib/styles/values/breakpoints.css';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  text-align: center;
}

.error {
  text-align: start;
}

@media only screen and bp-tablet-large-viewport {
  .container {
    min-width: 928px;
  }
}
